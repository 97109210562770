"use client";

import React from 'react'
import { usePathname } from 'next/navigation';
import useBioFont from "@/hooks/useBioFont";
import { useSelector } from 'react-redux';

const BioFontWrapper = () => {
  // @ts-ignore
  const user = useSelector((state) => state.user);
  const pathname = usePathname();
  const path = pathname?.split('/');
  const isSimpleLanding =
    process.env.NODE_ENV === 'development'
      ? path[2] === 'lp' || (!path[1] && user?.homePagePath?.split('/')[0] === 'lp')
      : path[1] === 'lp' || (!path[0] && user?.homePagePath?.split('/')[0] === 'lp');

  if (!isSimpleLanding) useBioFont();

  return null
}

export default BioFontWrapper;
