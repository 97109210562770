import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WebFont from "webfontloader";

import { themeOptions } from "../assets/linktree-themes";

const defaultTheme = themeOptions[0];

const useBioFont = (lpFont) => {
  const user = useSelector((state) => state.user);
  const [activeFamilyName, setActiveFamilyName] = useState(null);

  useEffect(() => {
    const path =
      typeof window !== "undefined" && window.location.pathname?.split("/");
    const isBioPage =
      process.env.NODE_ENV === "development"
        ? path[2] === "bio" || (!path[1] && user?.homePagePath === "bio")
        : path[1] === "bio" || (!path[0] && user?.homePagePath === "bio");
    const isSupportPage =
      process.env.NODE_ENV === "development"
        ? path[2] === "support" ||
          (!path[1] && user?.homePagePath === "support")
        : path[1] === "support" ||
          (!path[0] && user?.homePagePath === "support");

    const isSimpleLanding =
      process.env.NODE_ENV === "development"
        ? path[2] === "lp" ||
          (!path[1] && user?.homePagePath?.split("/")[0] === "lp")
        : path[1] === "lp" ||
          (!path[0] && user?.homePagePath?.split("/")[0] === "lp");

    if (isBioPage && user?.linkTree?.fontFamily) {
      const { fontName } = user.linkTree.fontFamily
        ? JSON.parse(user.linkTree.fontFamily)
        : defaultTheme.fontFamily;

      setActiveFamilyName(fontName);
    }

    if (isSupportPage && user?.support?.fontFamily) {
      const { fontName } = user.support.fontFamily
        ? JSON.parse(user.support.fontFamily)
        : defaultTheme.fontFamily;

      setActiveFamilyName(fontName);
    }

    if (isSimpleLanding && lpFont?.fontName) {
      setActiveFamilyName(lpFont.fontName || defaultTheme.fontFamily);
    }
  }, [user, lpFont]);

  useEffect(() => {
    if (activeFamilyName) {
      WebFont.load({
        google: {
          families: [activeFamilyName],
        },
      });
    }
  }, [activeFamilyName]);
};

export default useBioFont;
